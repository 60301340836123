import { useEffect } from 'react'
import Seo from '../components/seo'
import home_page from '../content/home_page'
import LoaderPennyLane from '../components/LoaderPennyLane/LoaderPennyLane'
import { useAuth0 } from '@auth0/auth0-react'

const { meta_title, meta_description } = home_page

const Login = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect({
      appState: { targetUrl: '/' },
    })
  }, [])

  return (
    <div className="Login">
      <LoaderPennyLane isFullPage={true} text="loading..." />
    </div>
  )
}

export default Login

export const Head = () => {
  return <Seo title={`Login | ${meta_title}`} description={meta_description} />
}
